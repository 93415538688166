
if (home) {
    var winWT = $(window).innerWidth();
    var winHT = $(window).innerHeight();

    $(document).ready(function () {

        //if(winWT > 1025){
        intro();

        TweenMax.set(".red-bg", { opacity: 0, 'width': '0', ease: Power2.easeInOut });
        TweenMax.set(".banner-hritik", { opacity: 0, scale: 0, ease: Power2.easeInOut });

        function intro() {
            var tl = new TimelineLite();
            tl.fromTo(".red-bg", 0.3, { opacity: 0, 'width': '0' }, { opacity: 1, 'width': '100%' })
            tl.fromTo(".dot-bg", 0.3, { opacity: 0 }, { opacity: 1 })
            tl.fromTo(".dotring", 1, { opacity: 0 }, { opacity: 1 }, "middle")
            tl.to(".dotring", 20, { rotation: 360, ease: Linear.easeNone, repeat: -1 }, "middle")

            //TweenMax.to(".dot-bg", 0.5, {alpha:0, repeatDelay:0.5, repeat:-1, yoyo:true}, "middle")
            tl.fromTo(".logo-left", 0.3, { opacity: 0 }, { opacity: 1 }, "middle")
            tl.fromTo(".logo-right", 0.3, { opacity: 0, 'right': '-100%' }, { opacity: 1, 'right': '0%' }, "middle")


            tl.fromTo(".banner-hritik", 0.3, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, "middle")
            tl.fromTo(".banner-caption", 0.3, { opacity: 0, x: 40 }, { opacity: 1, x: 0 }, "middle")
            //tl.fromTo(".power8", 0.3, { opacity:0, scale:0}, { opacity:1, scale:1}, "middle")
            tl.fromTo(".banner-product", 0.3, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, "middle")
            tl.fromTo(".tagline", 0.3, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, "middle")
        }

        // init controller
        var controller = new ScrollMagic.Controller();

        // build scene
        // build tween
        var tween = new TimelineMax();
        tween.fromTo("#elements .element-head", 0.3, { opacity: 0, y: 30 }, { opacity: 1, y: 0 })
        tween.fromTo("#elements .tohideWith", 0.3, { opacity: 0, y: 30 }, { opacity: 1, y: 0 })

        tween.fromTo(".ayurveda-img img", 1, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, ease: Circ.easeInOut })
        tween.fromTo(".ayurveda-img .ring", 1, { opacity: 0 }, { opacity: 1 }, "middle")
        tween.to(".ayurveda-img .ring", 10, { rotation: 360, ease: Linear.easeNone, repeat: -1 }, "middle")
        tween.fromTo(".ayurveda-left ul li", 0.5, { opacity: 0, x: 100 }, { delay: 1, opacity: 1, x: 0 }, "middle")
        tween.fromTo(".ayurveda-right ul li", 0.5, { opacity: 0, x: -100 }, { delay: 1, opacity: 1, x: 0 }, "middle")
        tween.fromTo("#elements .btn-main2", 0.3, { opacity: 0, y: 20 }, { opacity: 1, y: 0 })

        //tween.to(".ayurveda-img img", 1, {rotation:-360, ease:Linear.easeNone, repeat:-1}, "asas");
        //tween.To(".ayurveda-img img", 1, {rotation:90, transformOrigin:"left 50%"}, "asas");
        // build scene
        var scene = new ScrollMagic.Scene({ triggerElement: "#elements", duration: 0, offset: -50 })
            .setTween(tween)
            .addTo(controller);


        var vid = document.getElementById("homeVideo");
        var source = document.createElement("source");
        source.id = "hvid";
        source.setAttribute("type", "video/mp4");
        vid.appendChild(source);

        if (window.matchMedia("(max-width: 600px)").matches) {
            //vid.pause();
            source.removeAttribute("src");
            source.setAttribute("src", "assets/images/banner/mob-home-banner.mp4");
            vid.load();
            vid.play();
        } else if (window.matchMedia("(max-width: 768px)").matches) {
            //vid.pause();
            source.removeAttribute("src");
            source.setAttribute("src", "assets/images/banner/tab-home-banner.mp4");
            vid.load();
            vid.play();
        } else {
            //vid.pause();
            source.removeAttribute("src");
            source.setAttribute("src", "assets/images/banner/home-banner.mp4");
            vid.load();
            vid.play();
        }

        window.addEventListener("resize", function () {
            var vid = document.getElementById("homeVideo");
            var source = document.getElementById("hvid");

            if (window.matchMedia("(max-width: 600px)").matches) {
                //vid.pause();
                source.removeAttribute("src");
                source.setAttribute("src", "assets/images/banner/mob-home-banner.mp4");
                vid.load();
                vid.play();
            } else if (window.matchMedia("(max-width: 768px)").matches) {
                //vid.pause();
                source.removeAttribute("src");
                source.setAttribute("src", "assets/images/banner/tab-home-banner.mp4");
                vid.load();
                vid.play();
            } else {
                //vid.pause();
                source.removeAttribute("src");
                source.setAttribute("src", "assets/images/banner/home-banner.mp4");
                vid.load();
                vid.play();
            }
        });

        //}
    });

    

}

if(media || home){


(function ($) {
    $(window).on('scroll', function () {
        var window_top = $(window).scrollTop() + 1;
        if (window_top > 50) {
            $('.site-navigation').addClass('menu_fixed animated fadeInDown');
        } else {
            $('.site-navigation').removeClass('menu_fixed animated fadeInDown');
        }
    });

    // SCROLL TO TOP

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 70) {
            $('.scroll-to-top').addClass('reveal');
        } else {
            $('.scroll-to-top').removeClass('reveal');
        }
    });



    /* ---------------------------------------------
            owl-carousel
   --------------------------------------------- */


    $('.review').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: false,
        nav: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 2,
            }
        }
    })

    $('.featureSlide').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 3000,
        nav: true,
        navText: ["<i class='fa fa-chevron-circle-left fa-2x'></i>", "<i class='fa fa-chevron-circle-right fa-2x'></i>"],
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        }
    })


    $('.mediaSlide').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        items: 1,
        navText: ["<i class='fa fa-chevron-circle-left fa-2x'></i>", "<i class='fa fa-chevron-circle-right fa-2x'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    })

    $('.tvcSlide').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        items: 1,
        navText: ["<i class='fa fa-chevron-circle-left fa-2x'></i>", "<i class='fa fa-chevron-circle-right fa-2x'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 1
            }
        }
    })

    //covert power of ayurveda to slick

    var $slick_slider = $('.ayurvedaSlide');
    var settings = {
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,


    }
    $slick_slider.slick(settings);

    // reslick only if it's not slick()



    if ($(window).width() >= 768) {
        if ($slick_slider.hasClass('slick-initialized')) {
            $slick_slider.slick('unslick');
        }
        return
    } else if (!$slick_slider.hasClass('slick-initialized')) {
        return $slick_slider.slick(settings);
    }


    // Counter

    $('.counter').counterUp({
        delay: 10,
        time: 1000
    });



}(jQuery));

}